let activestUrls = [
    'portal.activestwm.com',
    'activest-test-portal.grokdata.tech',
    'activest-test-portal-b.grokdata.tech',
    'activest-portal-staging.whitepeaktech.com'
];

let axxetsUrls = [
    'portal.axxets.bs',
    'axxets-test-portal.grokdata.tech',
    'axxets-test-portal-b.grokdata.tech',
    'axxets-portal-staging.whitepeaktech.com'
];


let tenants = {
    'activest': activestUrls,
    'axxets': axxetsUrls
};


let prodApiUrls = {
    'activest': 'portal-api.activestwm.com',
    'axxets': 'portal-api.axxets.bs'
};

export function getUserDomain(){

    let currentDomain = window.location.hostname;
    for(const tenant of Object.keys(tenants)){
        let tenantUrls = tenants[tenant];
        if(tenantUrls.includes(currentDomain)){
            return tenant
        }
    }

    return 'axxets'

}

export function getTenantApiUrl(userDomain){
    let apiUrl = `https://${prodApiUrls[userDomain]}`;
    return apiUrl || 'https://portal-api.activestwm.com'
}