<template>
  <div>
    <v-container class="a-container">
      <div
        class="display-1 mt-3 font-weight-light mb-2"
        :class="mobileCentering"
      >
        <span>Welcome, </span
        ><span
          class="font-weight-bold username-welcome"
          v-text="$store.state.userName"
          >Username</span
        >!
      </div>
      <v-divider></v-divider>
      <v-row v-if="!isMobile" class="mt-3" :justify="mobileFilterCentering">
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            label="Group"
            v-model="selectedGroupFilter"
            :items="groups"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            label="Manager"
            v-model="selectedManagerFilter"
            :items="managers"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12" sm="3">
          <clearable-chips-selector
            :items="accountIds"
            label="Account"
            v-model="selectedAccountFilter"
          >
          </clearable-chips-selector>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <allocation-donut-card
            :data="filteredConsPos"
            view-by="category"
            id="dashboard"
            :clickable-chart="false"
          >
          </allocation-donut-card>
        </v-col>
        <v-col cols="12" lg="6">
          <top-positions-card :data="filteredConsPos"></top-positions-card>
        </v-col>
        <v-col cols="12" lg="6">
          <as-of-table-card
            :loading="consPosLoading"
            :table-data="statusByBank"
          ></as-of-table-card>
        </v-col>
      </v-row>
    </v-container>
    <mobile-filter-pane>
      <template v-slot:filters>
        <v-col cols="12">
          <clearable-chips-selector
            label="Group"
            v-model="selectedGroupFilter"
            :items="groups"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12">
          <clearable-chips-selector
            label="Manager"
            v-model="selectedManagerFilter"
            :items="managers"
          >
          </clearable-chips-selector>
        </v-col>
        <v-col cols="12">
          <clearable-chips-selector
            :items="accountIds"
            label="Account"
            v-model="selectedAccountFilter"
          >
          </clearable-chips-selector>
        </v-col>
      </template>
    </mobile-filter-pane>
  </div>
</template>

<script>
import AllocationDonutCard from "../components/reports/AllocationDonutCard";
import TopPositionsCard from "../components/reports/TopPositionsCard";
import AsOfTableCard from "../components/reports/AsOfTableCard";
import ClearableChipsSelector from "../components/base/filters/ClearableChipsSelector";
import { mapState, mapGetters } from "vuex";
import MobileFilterPane from "../components/base/filters/MobileFilterPane";

export default {
  name: "Dashboard",
  components: {
    AllocationDonutCard,
    TopPositionsCard,
    ClearableChipsSelector,
    MobileFilterPane,
    AsOfTableCard
  },
  data() {
    return {
      allocationReport: {
        reportTitle: "Allocation",
        link: "/reports/allocation"
      },
      topTenPositionsReport: {
        reportTitle: "Top 10 Positions"
      }
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.mdAndDown;
    },

    mobileCentering() {
      return this.isMobile ? "text-center" : "";
    },

    mobileFilterCentering() {
      return this.isMobile ? "center" : null;
    },

    navMessageAboveBelow() {
      return this.isMobile ? "below" : "above";
    },
    ...mapGetters([
      "accountIds",
      "groups",
      "managers",
      "consPosLoading",
      "filteredConsPos",
      "statusByBank"
    ]),
    ...mapState(["groupFilter", "managerFilter", "accountFilter"]),
    selectedGroupFilter: {
      set(groups) {
        this.$store.commit("setGroupFilter", groups);
      },
      get() {
        return this.groupFilter;
      }
    },
    selectedManagerFilter: {
      set(managers) {
        this.$store.commit("setManagerFilter", managers);
      },
      get() {
        return this.managerFilter;
      }
    },
    selectedAccountFilter: {
      set(accounts) {
        this.$store.commit("setAccountFilter", accounts);
      },
      get() {
        return this.accountFilter;
      }
    }
  }
};
</script>

<style scoped>
.username-welcome {
  color: var(--v-primary-base);
}
</style>
