<template>
    <div>
        <v-dialog
            v-model="forgotDialog"
            key="forgot-dialog"
            max-width="600"
        >
            <v-card
            >

                <v-card-text>
                    <div class="pt-3">
                        <span
                            class="headline"
                        >
                            Forgot your username, password, or Google Authenticator device?
                        </span>
                    </div>

                    <p class="mb-0 mt-3">
                        If you've forgotten your username, password, or have lost access to your Google Authenticator device,
                        please contact your advisor.
                    </p>
                </v-card-text>
            </v-card>
        </v-dialog>
        <transition name="fade" mode="out-in">
            <v-form
                v-model="authFormValid"
                class="login-box text-center"
                @submit.prevent="authenicateUser"
                v-if="window==='authenticate'"
                key="authenticate"
            >
                <v-text-field
                    label="Username"
                    name="username"
                    prepend-inner-icon="mdi-account-circle"
                    :rules="[formRules.mustExist]"
                    type="text"
                    color="primary"
                    class="login-field"
                    v-model="username"
                    filled
                ></v-text-field>
                <v-text-field
                    label="Password"
                    name="password"
                    prepend-inner-icon="mdi-lock"
                    type="password"
                    :rules="[formRules.mustExist]"
                    color="primary"
                    class="login-field"
                    v-model="password"
                    :error-messages="passwordWrongMessage"
                    filled
                ></v-text-field>
                <v-btn
                    block
                    tile
                    type="submit"
                    color="primary"
                    :loading="loginLoading"
                    :disabled="authDisabled"
                    class="login-button"
                >
                    Log In <v-icon>mdi-arrow-right</v-icon>
                </v-btn>
                <a class="forgot-link" @click.stop="toggleForgotDialog">Forgot your username or password?</a>
            </v-form>
            <v-form
                v-model="totpFormValid"
                v-if="window==='verify'"
                class="login-box text-center"
                @submit.prevent="verifyTOTP"
                key="verify"
            >
                <v-text-field
                    v-model="totp"
                    class="login-field totp-field"
                    label="Google Authenticator Code"
                    prepend-inner-icon="mdi-cellphone-lock"
                    :rules="[formRules.mustExist]"
                    hint="Enter your one-time passcode from Google Authenticator."
                    persistent-hint
                    :error-messages="totpWrongMessage"
                    type="number"
                    filled
                >
                </v-text-field>
                <v-btn
                    color="primary"
                    tile
                    type="submit"
                    class="login-button"
                    block
                    :loading="totpLoading"
                    :disabled="totpDisabled"
                >
                    Verify Google Authenticator <v-icon>mdi-check</v-icon>
                </v-btn>
                <a class="forgot-link" @click.stop="toggleForgotDialog">Lost access to your Google Authenticator device?</a>
            </v-form>
            <div
                v-if="window==='create-device'"
                class="login-box text-center pt-5"
            >
                <vue-qrcode
                    :value="qrCodeURI"
                    :options="{width: '8rem'}"
                ></vue-qrcode>
                <p class="message-text">Please scan the QR code using the Google Authenticator app.</p>
                <v-btn
                    color="primary"
                    tile
                    class="login-button"
                    @click="confirmTOTPCreation"
                >
                    Got it!
                </v-btn>
            </div>
        </transition>
    </div>
</template>

<script>
    import formRules from "../../utils/form.utils";
    import VueQrcode from '@chenfengyuan/vue-qrcode';
    import {createTOTPDevice} from "../../utils/api.utils";

    export default {
        name: "LogInBox",
        components: {
            VueQrcode
        },
        data() {
            return {
                username: '',
                password: '',
                totp: '',
                authFormValid: false,
                loginLoading: false,
                formRules: formRules,
                totpLoading: false,
                passwordWrong: false,
                totpWrong: false,
                window: 'authenticate',
                qrCodeURI: '',
                totpFormValid: false,
                forgotDialog: false
            }
        },

        methods: {
            async authenicateUser(){
                this.loginLoading = await true;
                await this.$store.dispatch('authenticateUser', {username: this.username, password: this.password})
                    .then(response => {

                        if (response.data.hasOTPDevice){
                            this.window = 'verify'
                        } else {
                            createTOTPDevice()
                                .then(response => {
                                    this.qrCodeURI = response.data.url;
                                    this.window = 'create-device'
                                })
                                .catch(error => {
                                    console.log(error)
                                })
                        }

                    })
                    .catch(error => {
                        if (error.response.status === 401){
                            this.password = '';
                            this.passwordWrong = true
                        }
                    });

                this.loginLoading = await false;
            },

            async verifyTOTP(){
                this.totpLoading = await true;
                await this.$store.dispatch('verifyTOTP', this.totp)
                    .then(() => {
                        if (this.$route.query.next){
                            this.$router.push(this.$route.query.next)
                        } else {
                           this.$router.push('/')
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 403){
                            this.totp = '';
                            this.totpWrong = true;
                        }

                        console.log(error)
                    });
                this.totpLoading = false;
            },

            confirmTOTPCreation(){
                this.window = 'verify'
            },

            toggleForgotDialog(){
                this.forgotDialog = !this.forgotDialog
            }

        },

        computed: {
            authDisabled() {
                return !this.authFormValid && !this.loginLoading
            },

            totpDisabled(){
                return !this.totpFormValid && !this.totpLoading
            },

            passwordWrongMessage() {

                if (this.passwordWrong && !this.password){
                    return 'This username and password do not match.'
                } else {
                    return ''
                }

            },

            totpWrongMessage(){
                if (this.totpWrong && !this.totp){
                    return 'This passcode is incorrect.'
                } else {
                    return ''
                }
            }
        }
    }
</script>

<style scoped>
    .login-box{
        max-width: 30rem;
        margin: 0 auto;
        padding: 1rem;
        border-radius: 5px;
        background: rgba(255, 255, 255, .15);
    }

    .login-field >>> .v-text-field__slot input {
        color: white !important;
    }

    .login-field >>> .v-label {
        color: white;
    }

    .login-field >>> .v-icon {
        color: var(--v-primary-base);
    }

    .login-button{
        margin-bottom: 2rem;
        margin-top: 1rem;
    }

    .forgot-link{
        color: darkgray;
        text-decoration: none;

    }

    .message-text{
        color: darkgray;
    }

    .forgot-link:hover{
        color: var(--v-primary-base);
        text-decoration: underline;
    }

    .totp-field >>> .v-messages__wrapper{
        color: white;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity .3s;
    }
    .fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
        opacity: 0;
    }

    .headline{
        color: black;
    }
</style>