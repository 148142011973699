<template>
    <v-bottom-navigation
        background-color="secondary"
        class="elevation-0"
        color="primary"
        dark
        v-if="showBottomNav"
        fixed
    >
        <v-btn
            key="home"
            :to="isAdmin ? '/admin-choose' : '/'"
        >
            <span>Home</span>
            <v-icon>mdi-home</v-icon>
        </v-btn>
        <v-btn
            v-for="(tab, index) in tabs"
            :key="index"
            :to="tab.link"
        >
            <span>{{ tab.name }}</span>
            <v-icon>{{ tab.icon }}</v-icon>
        </v-btn>
    </v-bottom-navigation>
</template>

<script>
    export default {
        name: "MobileBottomNav",
        data() {
            return {
                tabs: [
                    {name: 'Portfolio', link: '/portfolio', icon: 'mdi-chart-pie'},
                    {name: 'Performance', link: '/performance', icon: 'mdi-chart-line'},
                    {name: 'Reports', link: '/reports', icon: 'mdi-file-document'},
                ]
            }
        },
        computed: {
            isAdmin(){
                return this.$store.getters.isAdmin
            },
            showBottomNav(){
                return this.$vuetify.breakpoint.smAndDown
            }
        }
    }
</script>

<style scoped>

</style>