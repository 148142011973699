<template>
    <div>
        <v-dialog
            v-model="showPane"
            fullscreen
            transition="dialog-bottom-transition"
        >
            <v-card>
                <v-toolbar tile dark color="primary">
                    <v-toolbar-title>Filters</v-toolbar-title>
                    <v-spacer></v-spacer>
                    <v-btn
                        text
                        color="white"
                        @click="showPane = false">
                        Save
                    </v-btn>
                </v-toolbar>
                <v-card-text>
                    <v-container fluid>
                        <v-row class="mt-3" justify="center">
                            <slot name="filters"></slot>
                        </v-row>
                    </v-container>
                </v-card-text>
            </v-card>
        </v-dialog>
        <v-btn
            color="primary"
            class="filter-button"
            fixed
            fab
            v-if="isMobile"
            title="filters"
            @click.stop="showPane = true"
        ><v-icon>mdi-filter</v-icon>
        </v-btn>
    </div>
</template>

<script>
    export default {
        name: "MobileFilterPane",
        data() {
            return {
                showPane: false
            }
        },
        computed: {
            isMobile(){
                return this.$vuetify.breakpoint.mdAndDown
            }
        }
    }
</script>

<style scoped>
.filter-button {
    bottom: 5rem;
    right: 1rem;
}
</style>