<template>
    <v-container
        fill-height
        class="login-background"
        fluid
    >
        <v-row
            justify="center"
            align="center"
        >
            <v-col cols="auto">
                <tenant-logo
                    placement="login"
                >
                </tenant-logo>
            </v-col>
            <v-col cols="12">
                <log-in-box></log-in-box>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import LogInBox from '../components/login/LogInBox'
    import TenantLogo from '../components/base/TenantLogo'

    export default {
        name: "LogIn",
        components: {
            LogInBox, TenantLogo
        }
    }
</script>

<style scoped>
    .login-background{
        background-color: var(--v-secondary-base);
    }


</style>