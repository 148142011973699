<template>
  <report-summary-card
    report-title="As of Date per Custodian"
    :loading="loading"
    report-link=""
    :show-search="false"
  >
    <template v-slot:table>
      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :mobile-breakpoint="0"
        disable-sort
        :options="{ itemsPerPage: 5 }"
      >
      </v-data-table>
    </template>
  </report-summary-card>
</template>

<script>
import ReportSummaryCard from "../dashboard/ReportSummaryCard";

export default {
  name: "AsOfTableCard",
  props: ["tableData", "loading"],
  components: {
    ReportSummaryCard
  },
  data() {
    return {
      tableHeaders: [
        { text: "Custodian", value: "custodian" },
        { text: "Date", value: "as_of_date", align: "end" }
      ]
    };
  }
};
</script>

<style scoped></style>
