import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store'
import LogIn from '../views/LogIn'
import AppScreen from '../views/AppScreen'
import Dashboard from '../views/Dashboard'

Vue.use(VueRouter);

const routes = [
  {
      path: '/',
      component: AppScreen,
      meta: {
          requiresAuth: true
      },
      beforeEnter(to, from, next){
          const hasConsPosData = store.state.userConsPos.length > 0;
          const hasDocumentData = store.state.userDocuments.length > 0;
          const hasTransactionData = store.state.userTransactions.length > 0;
          const hasByAccountPerfData = store.state.userByAccountPerformanceRecords.length > 0;
          const hasYearlyPerfData = store.state.yearlyPerformance.length > 0;
          if (!hasConsPosData) store.dispatch('getConsPos');
          if (!hasDocumentData) store.dispatch('getUserDocuments');
          if (!hasTransactionData) store.dispatch('getTransactions');
          if (!hasByAccountPerfData) store.dispatch('getByAccountPerf');
          if (!hasYearlyPerfData) store.dispatch('getYearlyPerf');
          return next()
      },
      children: [
          {
              path: '',
              name: 'MainDashboard',
              component: Dashboard
          },
          {
              path: 'portfolio',
              name: 'Portfolio',
              component: () => import(/* webpackChunkName: "portfolio" */ '../views/Portfolio.vue')
          },
          {
              path: 'portfolio/allocation',
              name: 'Allocation',
              component: () => import(/* webpackChunkName: "allocation" */ '../views/portfolio/Allocation.vue')
          },
          {
              path: 'portfolio/fixed-income',
              name: 'FixedIncome',
              component: () => import(/* webpackChunkName: "fixedIncome" */ '../views/portfolio/FixedIncome.vue')
          },
          {
              path: 'portfolio/projected-income',
              name: 'ProjectedIncome',
              component: () => import(/* webpackChunkName: "projectedIncome" */ '../views/portfolio/ProjectedIncome.vue')
          },
          {
              path: 'reports',
              name: 'Reports',
              component: () => import(/* webpackChunkName: "reports" */ '../views/Reports.vue')
          },
          {
              path: 'reports/statements',
              name: 'statements',
              component: () => import(/* webpackChunkName: "statements" */ '../views/reports/Statements.vue')
          },
          {
              path: 'reports/research',
              name: 'Research',
              component: () => import(/* webpackChunkName: "research" */ '../views/reports/Research.vue')
          },
          {
              path: 'admin',
              name: 'Admin',
              component: () => import(/* webpackChunkName: "admin" */ '../views/Admin.vue'),
              meta: {
                  requiresAuth: true,
                  requiresAdmin: true
              }
          },
          {
              path: 'disclaimer',
              name: 'Disclaimer',
              component: () => import(/* webpackChunkName: "disclaimer" */ '../views/Disclaimer.vue')
          },
          {
              path: 'activity',
              name: 'Activity',
              component: () => import(/* webpackChunkName: "activity" */ '../views/Activity.vue')
          },
          {
              path: 'performance',
              name: 'Performance',
              component: () => import(/* webpackChunkName: "performance" */ '../views/Performance.vue')
          },
          {
              path: '/admin-choose',
              name: 'AdminChoose',
              component: () => import(/* webpackChunkName: "adminChoose" */ '../views/AdminChooseHome.vue'),
              meta: {
                  requiresAuth: true,
                  requiresAdmin: true
              }
          },

    ]
  },
  {
      path: '/login',
      name: 'Login',
      component: LogIn
  },
  {
    path: '*',
    component: () => import(/* webpackChunkName: "404" */ '../views/FOF.vue')
  }
];

const router = new VueRouter({
    mode: 'history',
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        }
            return { x: 0, y: 0 }

    },
    base: process.env.BASE_URL,
    routes
});

router.beforeEach((to, from, next) => {

    if (to.matched.some(record => record.meta.requiresAuth)) {
        if (store.state.isTOTPVerified){
            store.dispatch('inspectToken', to.path);
            if (to.matched.some(record => record.meta.requiresAdmin)){
                if (store.getters.isAdmin) return next();
                return next('/')
            }
            return next()
        } else {
            return next('/login?next=' + to.path)
        }
    }

     return next()
});

export default router
