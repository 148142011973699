import colors from "./colors.utils";
import { currencyFormatter } from "./format.utils";

export function aggregateConsPosDataByCategory(data, aggBy = "category") {
  let aggData = {};
  let sorting = {};
  let totalValue = 0;

  data.forEach(record => {
    totalValue = totalValue + record.usd_amount;
    if (aggData[record[aggBy]]) {
      aggData[record[aggBy]] = aggData[record[aggBy]] + record.usd_amount;
    } else {
      aggData[record[aggBy]] = record.usd_amount;
    }

    sorting[record[aggBy]] = record.sorting[aggBy];
  });

  let newData = [];

  let i = 0;
  for (const aggKey of Object.keys(aggData)) {
    newData.push({
      aggBy: aggKey,
      usd_amount: aggData[aggKey],
      percent: aggData[aggKey] / totalValue,
      sorting: sorting[aggKey]
    });
    i = i + 1;
  }

  let sortFunc = sortBy("sorting");
  newData = newData.sort(sortFunc);
  let newDataWithColors = [];
  for (let j = 0; j < newData.length; j++) {
    newDataWithColors.push({ ...newData[j], color: colors[j] });
  }

  return newDataWithColors;
}

export function aggregateConsPosDataBySubCategoryForAllocation(data) {
  let aggByCategory = aggregateConsPosDataByCategory(data, "category");
  let categoryColors = {};

  aggByCategory.forEach(record => {
    categoryColors[record.aggBy] = record.color;
  });

  let aggData = {};
  let subCategoryColors = {};
  let categorySort = {};
  let subCategorySort = {};
  let totalValue = 0;

  data.forEach(record => {
    totalValue = totalValue + record.usd_amount;
    if (aggData[record.sub_category]) {
      aggData[record.sub_category] =
        aggData[record.sub_category] + record.usd_amount;
    } else {
      aggData[record.sub_category] = record.usd_amount;
    }

    categorySort[record.sub_category] = record.sorting.category;
    subCategorySort[record.sub_category] = record.sorting.sub_category;
    subCategoryColors[record.sub_category] = categoryColors[record.category];
  });

  let newData = [];

  for (const aggKey of Object.keys(aggData)) {
    newData.push({
      aggBy: aggKey,
      usd_amount: aggData[aggKey],
      percent: aggData[aggKey] / totalValue,
      color: subCategoryColors[aggKey],
      categorySorting: categorySort[aggKey],
      subCategorySorting: subCategorySort[aggKey]
    });
  }

  return newData;
}

export function aggregateProjectedIncomeByAgg(data, aggBy = "category") {
  let aggIdx = 0;
  let dataSets = [];
  let aggByItems = [...new Set(data.map(position => position[aggBy]))];
  let monthFields = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve"
  ].map(num => "pi_month_" + num);

  aggByItems.forEach(aggItem => {
    let aggData = {
      label: aggItem,
      backgroundColor: colors[aggIdx],
      // First twelve items are the months, the 13th is for "Other" Projected Income.
      data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
    };

    data.forEach(record => {
      if (record[aggBy] === aggItem) {
        let totalMonthlyProjectedIncome = 0;
        for (let i = 0; i < 12; i++) {
          aggData.data[i] += record[monthFields[i]];
          totalMonthlyProjectedIncome += record[monthFields[i]];
        }

        aggData.data[12] +=
          record["projected_income"] - totalMonthlyProjectedIncome;
      }
    });

    dataSets.push(aggData);
    aggIdx++;
  });

  return dataSets;
}

export function aggregateProjectedIncomeByMonth(data) {
  let monthFields = [
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
    "ten",
    "eleven",
    "twelve"
  ].map(num => "pi_month_" + num);

  // First twelve items are the months, the 13th is for "Other" Projected Income.
  let newData = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];

  data.forEach(record => {
    let i = 0;
    let totalMonthlyProjectedIncome = 0;
    monthFields.forEach(month => {
      newData[i] += record[month];
      totalMonthlyProjectedIncome += record[month];
      i++;
    });

    newData[12] +=
      record["projected_income"] - totalMonthlyProjectedIncome < 0
        ? 0
        : record["projected_income"] - totalMonthlyProjectedIncome;
  });

  return newData;
}

function default_cmp(a, b) {
  if (a == b) return 0;
  return a < b ? -1 : 1;
}

function getCmpFunc(primer, reverse) {
  let dfc = default_cmp, // closer in scope
    cmp = default_cmp;
  if (primer) {
    cmp = function(a, b) {
      return dfc(primer(a), primer(b));
    };
  }
  if (reverse) {
    return function(a, b) {
      return -1 * cmp(a, b);
    };
  }
  return cmp;
}

export function sortBy() {
  let fields = [],
    n_fields = arguments.length,
    field,
    name,
    cmp;

  for (let i = 0; i < n_fields; i++) {
    field = arguments[i];
    if (typeof field === "string") {
      name = field;
      cmp = default_cmp;
    } else {
      name = field.name;
      cmp = getCmpFunc(field.primer, field.reverse);
    }
    fields.push({
      name: name,
      cmp: cmp
    });
  }

  return function(A, B) {
    let name, result;
    for (let i = 0; i < n_fields; i++) {
      result = 0;
      field = fields[i];
      name = field.name;

      result = field.cmp(A[name], B[name]);
      if (result !== 0) break;
    }
    return result;
  };
}

export function disaggForTooltip(macroCategory, positionName, consposRecords) {
  let positionData = consposRecords
    .map(x => x)
    .filter(record => {
      return (
        record.position_name === positionName &&
        record.macro_category === macroCategory
      );
    });

  let aggResults = [];

  [
    ...positionData
      .reduce((r, o) => {
        const key = o.custodian + "-" + o.portfolio;

        const item =
          r.get(key) ||
          Object.assign({}, o, {
            nominal_amount: 0,
            usd_amount: 0,
            total_pi: 0
          });

        item.nominal_amount += o.nominal_amount;
        item.usd_amount += o.usd_amount;
        const totalMonthlyProjectedIncome =
          o.pi_month_one +
          o.pi_month_two +
          o.pi_month_three +
          o.pi_month_four +
          o.pi_month_five +
          o.pi_month_six +
          o.pi_month_seven +
          o.pi_month_eight +
          o.pi_month_nine +
          o.pi_month_ten +
          o.pi_month_eleven +
          o.pi_month_twelve;

        const otherProjectedIncome =
          o.projected_income - totalMonthlyProjectedIncome < 0
            ? 0
            : o.projected_income - totalMonthlyProjectedIncome;
        item.total_pi += totalMonthlyProjectedIncome;
        item.total_pi += otherProjectedIncome;

        return r.set(key, item);
      }, new Map())
      .values()
  ].forEach(record => {
    aggResults.push({
      portfolio: record.portfolio,
      custodian: record.custodian,
      nominal_amount: Number(record.nominal_amount).toLocaleString(),
      usd_amount: currencyFormatter.format(record.usd_amount),
      total_pi: currencyFormatter.format(record.total_pi)
    });
  });

  return aggResults;
}
