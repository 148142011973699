import {getUserDomain} from "./domains.utils";

let activestColors = [
    '#253746',
    '#43B02A',
    '#727272',
    '#000000',
    '#003459',
    '#00171F',
    '#4D7EA8',
    '#F65E04',
    '#AA341B',
    '#772E4E',
    '#253746',
    '#43B02A',
    '#727272',
    '#000000',
    '#003459',
    '#00171F',
    '#4D7EA8',
    '#F65E04',
    '#AA341B',
    '#772E4E',
    '#253746',
    '#43B02A',
    '#727272',
    '#000000',
    '#003459',
    '#00171F',
    '#4D7EA8',
    '#F65E04',
    '#AA341B',
    '#772E4E',
    '#253746',
    '#43B02A',
    '#727272',
    '#000000',
    '#003459',
    '#00171F',
    '#4D7EA8',
    '#F65E04',
    '#AA341B',
    '#772E4E',
];


let axxetsColors =  [
  '#394049',
  '#02CE7B',
  '#767B81',
  '#2B9361',
  '#CACBCE',
  '#8ED896',
  '#484E57',
  '#2A6451',
  '#003459',
  '#772E4E',
  '#394049',
  '#02CE7B',
  '#767B81',
  '#2B9361',
  '#CACBCE',
  '#8ED896',
  '#484E57',
  '#2A6451',
  '#003459',
  '#772E4E',
  '#394049',
  '#02CE7B',
  '#767B81',
  '#2B9361',
  '#CACBCE',
  '#8ED896',
  '#484E57',
  '#2A6451',
  '#003459',
  '#772E4E',
  '#394049',
  '#02CE7B',
  '#767B81',
  '#2B9361',
  '#CACBCE',
  '#8ED896',
  '#484E57',
  '#2A6451',
  '#003459',
  '#772E4E'
];

let userDomain = getUserDomain();
let colors = userDomain === 'axxets' ? axxetsColors : activestColors;

export function hexToRGBA(hex, alpha = 1) {
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    let c = hex.substring(1).split('')
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]]
    }
    c = '0x' + c.join('')
    return (
      'rgba(' +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') +
      `,${alpha})`
    )
  }
  throw new Error('Bad Hex')
}


export default colors
