import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import themes from "../themes";
import {getUserDomain} from "../utils/domains.utils";

Vue.use(Vuetify);

const domain = getUserDomain();
const page_theme = themes[domain] || themes['activest'];

const vuetify = new Vuetify({
  theme: {
    themes: {
      light: page_theme,
    },
    options: {
      customProperties: true
    }
  },
});

export default vuetify