<template>
    <v-app-bar
        app
        color="secondary"
        :height="barHeight"
        :z-index="2000"
    >
        <div>
            <v-menu
                bottom
                offset-y
                :close-on-content-click="false"
                :close-on-click="!$store.state.needsToChangePassword"
                v-model="showPasswordChangeForm"
                width="500"
                :z-index="2002"
            >
                <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        class="logout-link"
                        icon
                        title="Profile Actions"
                        v-bind="attrs"
                        @click="showPasswordChangeFormLatent = !showPasswordChangeFormLatent"
                        :disabled="$store.state.needsToChangePassword"
                    >
                        <v-icon>mdi-account</v-icon>
                    </v-btn>
                </template>
                <v-card width="500">
                    <v-card-title>
                        User Profile
                    </v-card-title>
                    <v-card-text>
                        <div class="d-flex flex-column">
                            <div class="d-flex flex-row">
                                <div>Username:</div>
                                <v-spacer></v-spacer>
                                <div v-text="$store.state.userName"></div>
                            </div>
                            <div class="mt-3">
                                <v-alert
                                    type="success"
                                    v-if="showChangeSuccess"
                                    dense
                                    text
                                >
                                    Password changed successfully.
                                </v-alert>
                                <v-alert
                                    type="info"
                                    v-else-if="$store.state.needsToChangePassword"
                                    dense
                                    text
                                >
                                    Please change your password to proceed.
                                </v-alert>
                                <v-alert
                                    type="error"
                                    v-else-if="showChangeError"
                                    dense
                                    text
                                >
                                    Something went wrong.
                                </v-alert>
                                <v-form
                                    v-model="newPasswordFormValid"
                                    @submit.prevent="changePassword"
                                    ref="changeForm"
                                >
                                    <v-text-field
                                        hide-details="auto"
                                        label="Current Password"
                                        outlined
                                        dense
                                        type="password"
                                        v-model="currentPassword"
                                        :rules="[mustExist]"
                                        :disabled="passwordChangeLoading"
                                        :error-messages="passwordWrongMessage"
                                    ></v-text-field>
                                    <v-alert
                                        v-if="showPasswordRules"
                                        text
                                        class="mt-2 mb-0"
                                        style="font-size: .75rem;"
                                        :color="passwordValid ? 'primary' : ''"
                                    >
                                        <div class="d-flex flex-row mb-4">
                                            <div v-for="lang in Object.keys(passwordRequirements)" :key="lang">
                                                <v-btn
                                                    x-small
                                                    text
                                                    :input-value="lang === activeLanguage ? 1 : 0"
                                                    :color="lang === activeLanguage ? 'primary' : 'lightgray'"
                                                    @click="activeLanguage = lang"
                                                >
                                                    {{ passwordRequirements[lang].display }}
                                                </v-btn>
                                            </div>
                                        </div>
                                        {{ activePasswordChangeRules.header }}:
                                        <ul>
                                            <li v-for="(rule, idx) in activePasswordChangeRules.rules" :key="idx">
                                                <span
                                                    v-text="rule.text"
                                                    :class="{'rule-accepted': validatorResults[idx], 'rule-invalid': !validatorResults[idx]}"
                                                ></span>
                                            </li>
                                        </ul>
                                    </v-alert>
                                    <v-text-field
                                        hide-details="auto"
                                        label="New Password"
                                        outlined
                                        dense
                                        type="password"
                                        class="mt-2"
                                        v-model="newPassword"
                                        :rules="[mustExist, ...activePasswordValidators]"
                                        :disabled="passwordChangeLoading"
                                        @focus="showPasswordRules = true"
                                    ></v-text-field>
                                    <v-text-field
                                        hide-details="auto"
                                        label="Confirm New Password"
                                        outlined
                                        dense
                                        class="mt-2"
                                        v-model="confirmNewPassword"
                                        :rules="[mustExist, passwordsMustMatch]"
                                        :disabled="passwordChangeLoading"
                                        type="password"
                                    ></v-text-field>
                                    <v-btn
                                        block
                                        color="primary"
                                        class="mt-2"
                                        type="submit"
                                        :disabled="!newPasswordFormValid || passwordChangeLoading"
                                        :loading="passwordChangeLoading"
                                    >
                                        Change Password
                                    </v-btn>
                                </v-form>
                            </div>
                            <v-btn
                                block
                                color="secondary"
                                class="mt-5"
                                @click="logOut"
                            >
                                Log Out <v-icon class="ml-2" color="white">mdi-logout</v-icon>
                            </v-btn>
                        </div>
                    </v-card-text>
                </v-card>
            </v-menu>

        </div>
        <v-toolbar-items v-if="!isScrolled" class="d-block mx-auto text-center" :class="topBarLogoPadding">
           <tenant-logo :scrolled="isScrolled" class="mx-auto mb-3" placement="navbar"></tenant-logo>
            <p v-show="!loading" class="as-of-text" :class="isScrolled ? 'scrolled-text' : ''">Data as of {{ dataAsOf }}</p>
            <v-progress-linear
                indeterminate
                v-show="loading"
            ></v-progress-linear>
        </v-toolbar-items>
        <template v-if="showTabs" v-slot:extension>
            <v-tabs
                centered
                background-color="transparent"
                dark
                slider-color="primary"
                active-class="active-tab"
            >
                <template
                    v-for="(tab, index) in tabs"
                >
                    <v-menu
                        v-if="tab.menu"
                        :key="index"
                        bottom
                        offset-y
                    >
                        <template v-slot:activator="{on}">
                            <v-tab
                                v-on="on"
                                :disabled="$store.state.needsToChangePassword"
                            >
                                <v-icon style="margin-right: .5rem;">{{ tab.icon }}</v-icon>
                                {{ tab.name }}

                            </v-tab>
                        </template>

                        <v-list color="secondary">
                            <v-list-item
                                v-for="(item, i) in tab.menu"
                                :key="i"
                                :to="item.link"
                                color="primary"
                                class="menu-item"
                            >
                                <v-list-item-title>
                                    <v-icon style="margin-right: .5rem;">{{ item.icon }}</v-icon>{{ item.name }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-menu>

                    <v-tab
                        :to="tab.link"
                        v-else
                        :key="index"
                        :disabled="$store.state.needsToChangePassword"
                    >
                        <v-icon style="margin-right: .5rem;">{{ tab.icon }}</v-icon>
                        {{ tab.name }}

                    </v-tab>
                </template>


                <v-tab
                    v-if="isAdmin"
                    key="admin"
                    to="/admin"
                    :disabled="$store.state.needsToChangePassword"
                >
                    <v-icon style="margin-right: .5rem;">mdi-cog</v-icon>
                    Admin
                </v-tab>
            </v-tabs>
        </template>
    </v-app-bar>
</template>

<script>
    import TenantLogo from '../TenantLogo'
    import {changePassword} from "../../../utils/api.utils";

    export default {
        name: "AppBar",
        components: {
            TenantLogo
        },
        data() {
            return {
                isScrolled: false,
                tabs: [
                    {name: 'Home', link: '/', icon: 'mdi-home'},
                    {
                        name: 'Portfolio',
                        menu: [
                            {name: 'Allocation', link: '/portfolio/allocation', icon: 'mdi-chart-pie'},
                            {name: 'Fixed Income', link: '/portfolio/fixed-income', icon: 'mdi-currency-usd'},
                            {name: 'Projected Income', link: '/portfolio/projected-income', icon: 'mdi-chart-timeline-variant'},
                        ],
                        icon: 'mdi-chart-pie'
                    },
                    {name: 'Activity', link: '/activity', icon: 'mdi-radar'},
                    {name: 'Performance', link: '/performance', icon: 'mdi-chart-line'},
                    {
                        name: 'Reports',
                        menu: [
                            {name: 'Statements', link: '/reports/statements', icon: 'mdi-file-document'},
                            {name: 'Research', link: '/reports/research', icon: 'mdi-flask'}
                        ],
                        icon: 'mdi-file-document'
                    },

                ],
                currentPassword: '',
                newPassword: '',
                confirmNewPassword: '',
                newPasswordFormValid: false,
                mustExist: v => !!v || 'This field is required.',
                passwordsMustMatch: v => (v === this.newPassword) || 'New password does not match.',
                passwordChangeLoading: false,
                passwordWrong: false,
                showChangeSuccess: false,
                showChangeError: false,
                showPasswordChangeFormLatent: false,
                showPasswordRules: false,
                activeLanguage: 'en',
                passwordRequirements: {
                    'en': {
                        display: 'In English',
                        header: 'Your new password must',
                        rules: [
                            {text: 'Be at least 8 characters long', validator: (v) =>  (v || '').length >= 8 || 'Password must follow rules above.'},
                            {text: 'Contain at least 1 lower-case character', validator: (v) => (/[a-z]/.test((v || ''))) || 'Password must follow rules above.'},
                            {text: 'Contain at least 1 upper-case character', validator: (v) => (/[A-Z]/.test((v || ''))) || 'Password must follow rules above.'},
                            {text: 'Contain at least 1 number', validator: (v) => (/[0-9]/.test((v || ''))) || 'Password must follow rules above.'},
                            {text: 'Contain at least one of the following characters: !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~', validator: (v) => (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test((v || ''))) || 'Password must follow rules above.'},
                        ]
                    },
                    'es': {
                        display: 'En Español',
                        header: 'La contraseña nueva debe',
                        rules: [
                            {text: 'Incluir al menos 8 caracteres', validator: (v) => (v || '').length >= 8 || 'La contraseña debe seguir las reglas descritas.'},
                            {text: 'Incluir al menos 1 minúscula', validator: (v) => (/[a-z]/.test((v || ''))) || 'La contraseña debe seguir las reglas descritas.'},
                            {text: 'Incluir al menos 1 mayúscula', validator: (v) => (/[A-Z]/.test((v || ''))) || 'La contraseña debe seguir las reglas descritas.'},
                            {text: 'Incluir al menos 1 número', validator: (v) => (/[0-9]/.test((v || ''))) || 'La contraseña debe seguir las reglas descritas.'},
                            {text: 'Incluir al menos 1 de los siguientes caracteres: !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~', validator: (v) => (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test((v || ''))) || 'La contraseña debe seguir las reglas descritas.'},
                        ]
                    },
                    'pt': {
                        display: 'Em Português',
                        header: 'A senha deve conter',
                        rules: [
                            {text: 'Pelo menos 8 caracteres', validator: (v) => (v || '').length >= 8 || 'A senha deve seguir as regras descritas.'},
                            {text: 'Pelo menos 1 letra minúscula', validator: (v) => (/[a-z]/.test((v || ''))) || 'A senha deve seguir as regras descritas.'},
                            {text: 'Pelo menos 1 maiúscula', validator: (v) => (/[A-Z]/.test((v || ''))) || 'A senha deve seguir as regras descritas.'},
                            {text: 'Pelo menos 1 número', validator: (v) => (/[0-9]/.test((v || ''))) || 'A senha deve seguir as regras descritas.'},
                            {text: 'Pelo menos 1 das seguintes caracteres: !"#$%&\'()*+,-./:;<=>?@[\\]^_`{|}~', validator: (v) => (/[!"#$%&'()*+,-./:;<=>?@[\]^_`{|}~]/.test((v || ''))) || 'A senha deve seguir as regras descritas.'},
                        ]
                    }
                }
            }
        },
        mounted() {
            window.addEventListener('scroll', this.onScroll)
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.onScroll)
        },
        computed: {
            activePasswordChangeRules(){
              return this.passwordRequirements[this.activeLanguage]
            },
            activePasswordValidators(){
              return this.activePasswordChangeRules.rules.map(x => x.validator)
            },
            validatorResults(){
              let results = {}
              const rules = this.activePasswordChangeRules.rules
              for (let i = 0; i < rules.length; i++){
                  const validator = rules[i].validator
                  const result = validator(this.newPassword)

                  // the validator passed "true" if the rule is passed and a string if not.
                  // therefore, if a boolean is not passed, we know the rule is not followed.
                  results[i] = typeof result === "boolean"
              }

                return results
            },
            passwordValid(){
              return Object.values(this.validatorResults).every(v => v)
            },
            isAdmin(){
                return this.$store.getters.isAdmin
            },
            loading(){
                return this.$store.getters.consPosLoading
            },

            userDomain () {
                return this.$store.getters.userDomain
            },

            topBarLogoPadding () {
                if (this.userDomain === 'activest'){
                    return 'py-5'
                }

                if (this.userDomain === 'axxets'){
                    return 'py-5'
                }

                return 'py-5'
            },

            showTabs () {
                return this.$vuetify.breakpoint.mdAndUp
            },

            dataAsOf(){
                return this.$store.getters.dataAsOf
            },
            barHeight(){
                if (this.showTabs) {
                    if (this.isScrolled) {
                        return '50px'
                    } else {
                        return '142px'
                    }
                } else {
                    if (this.isScrolled) {
                        return '50px'
                    } else {
                        return '155px'
                    }
                }
            },
            passwordPayload(){
                return {
                    'current_password': this.currentPassword,
                    'new_password': this.newPassword
                }
            },
            passwordWrongMessage(){
                if (this.passwordWrong && !this.currentPassword){
                    return 'This username and password do not match.'
                } else {
                    return ''
                }
            },
            showPasswordChangeForm: {
                get(){
                    return this.$store.state.needsToChangePassword || this.showPasswordChangeFormLatent
                },
                set(value) {
                    this.showPasswordChangeFormLatent = value

                    // if the rules are being shown and the user closed the password change form
                    if (this.showPasswordRules && !value){
                        // don't display the rules
                        this.showPasswordRules = false
                    }

                    // if the user closes the form, reset the form.
                    if (!value){
                        this.$refs.changeForm.reset()
                    }
                }
            }

        },
        methods: {
            logOut(){
                this.$store.dispatch('logOut')
            },
            onScroll() {
              this.isScrolled = window.pageYOffset > 0
            },
            async changePassword(){
                this.passwordChangeLoading = true
                this.passwordWrong = false
                try{
                    await changePassword(this.passwordPayload)
                    this.showChangeSuccess = true
                    this.$refs.changeForm.reset()

                    setTimeout(() => {
                        this.$store.commit('setChangePasswordStatus', false)
                    }, 1500)

                    setTimeout(() => {
                        this.showChangeSuccess = false
                    }, 10000)
                } catch(e) {
                    if (e.response.status === 400){
                        this.currentPassword = ''
                        this.passwordWrong = true

                    } else {
                        this.showChangeError = true
                        this.$refs.changeForm.reset()
                        setTimeout(() => {
                            this.showChangeError = false
                        }, 10000)
                    }
                }
                this.passwordChangeLoading = false
            }
        }

    }
</script>

<style scoped>

    .rule-accepted {
        text-decoration: line-through;
        color: var(--v-primary-base) !important;

    }

    .rule-invalid {

    }

    .as-of-text{
        color: gray;
        margin-bottom: 0;
    }

    .scrolled-text {
        font-size: .875rem;
        margin-top: -.5rem;
    }

    .active-tab{
        color: var(--v-primary-base) !important
    }

    .logout-link{
        position: absolute;
        top: .3rem;
        right: .3rem;
        color: var(--v-primary-base) !important;
    }

    >>>.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
        color: gray !important;
    }

    >>>.theme--light.v-icon {
        color: gray;
    }
</style>