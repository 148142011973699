import axios from "axios";
import store from "../store";
import router from "../router";
import { getUserDomain, getTenantApiUrl } from "./domains.utils";

const domain = getUserDomain();
const baseDomain =
  process.env.NODE_ENV.trim() === "production"
    ? getTenantApiUrl(domain)
    : "http://activest.com:80";

// const baseDomain = 'http://portal-api.axxets.bs:8000'
// const baseDomain = "http://portal-api.activestwm.com:8000";

axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.xsrfHeaderName = "X-CSRFTOKEN";

axios.interceptors.request.use(config => {
  const token = store.state.accessToken;
  if (token) {
    config.headers.Authorization = `JWT ${token}`;
    return config;
  }

  return config;
});

axios.interceptors.response.use(
  response => response,
  error => {
    const token = store.state.accessToken;
    const status = error.response ? error.response.status : 200;
    if (token) {
      if (status === 401) {
        return store
          .dispatch("inspectToken", router.currentRoute.path)
          .then(() => {
            const token = store.state.accessToken;
            error.config.headers.Authorization = `JWT ${token}`;
            return axios.request(error.config);
          })
          .catch(error => {
            return Promise.reject(error);
          });
      }
    }

    return Promise.reject(error);
  }
);

// AUTHORIZATION
export function obtainAuthToken(username, password) {
  const payload = {
    username: username,
    password: password
  };

  return axios.post(baseDomain + "/auth/obtain-token/", payload);
}

export function refreshAuthToken(payload) {
  return axios.post(baseDomain + "/auth/refresh-token/", payload);
}

export function verifyTOTP(totp) {
  return axios.post(baseDomain + "/otp/login/" + totp + "/");
}

export function createTOTPDevice() {
  return axios.post(baseDomain + "/otp/create/");
}

export function changePassword(payload) {
  return axios.post(baseDomain + "/auth/change-password/", payload);
}

// DOCUMENTS
export function getUserDocumentList() {
  return axios.get(baseDomain + "/documents/retrieve/");
}

export function getDocumentURL(documentID) {
  return axios.get(baseDomain + "/documents/retrieve/" + documentID + "/");
}

export function getDocumentTypes() {
  return axios.get(baseDomain + "/documents/types/");
}

export function getDocumentSubtypes() {
  return axios.get(baseDomain + "/documents/subtypes/");
}

export function uploadDocument(document) {
  return axios.post(baseDomain + "/documents/upload/", document);
}

// REPORTS
export function getUserConsPos() {
  return axios.get(baseDomain + "/reports/consolidated-positions/");
}

export function getUserTransactions() {
  return axios.get(baseDomain + "/reports/transactions/ledger/");
}

export function getUserByAccountPerformance() {
  return axios.get(
    baseDomain + "/reports/transactions/performance/by-account/"
  );
}

export function getConsposData(postParams) {
  return axios.post(
    baseDomain + "/reports/consolidated-positions/aggregate/",
    postParams
  );
}

export function getByAccountPerformance(params) {
  return axios.post(baseDomain + "/reports/performance/by-account/", params);
}

export function getYearlyPerformance() {
  return axios.get(baseDomain + "/reports/transactions/performance/yearly/");
}

export function getAdminKPI(kpiSlug) {
  return axios.get(baseDomain + "/reports/users/" + kpiSlug + "/");
}
