<template>
    <report-summary-card
        report-title="Allocation"
        :loading="loading"
        :report-link="link"
    >
        <template v-slot:header>
            <div class="text-center my-4 display-1 total-text font-weight-light">
                {{ totalValue }}
            </div>
        </template>
            <template v-slot:chart>
                <allocation-donut
                    :data="data"
                    :view-by="viewBy"
                    :id="id"
                    :clickable="clickableChart"
                ></allocation-donut>
            </template>
            <template v-slot:table>
                <div class="allocation-table mt-5">
                    <allocation-table
                    :data="data"
                    :view-by="viewBy"
                    :id="id + '-table'"
                    ></allocation-table>
                </div>

            </template>
    </report-summary-card>
</template>

<script>
    import {currencyFormatter} from "../../utils/format.utils";
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import AllocationDonut from '../reports/AllocationDonut';
    import AllocationTable from '../reports/AllocationTable';

    export default {
        name: "AllocationDonutCard",
        props: [
            'link', 'data', 'viewBy', 'id', 'clickableChart'
        ],
        components: {
            ReportSummaryCard, AllocationDonut, AllocationTable
        },
        data() {
            return {

            }
        },
        computed: {
            loading() {
                return this.$store.getters.consPosLoading
            },

            totalValue(){

                let totalValue = 0;
                this.data.forEach(record => {
                    totalValue = totalValue + record.usd_amount
                });

                return currencyFormatter.format(totalValue)

            },
        }
    }
</script>

<style scoped>
    .allocation-table{
        max-height: 17rem;
        overflow-y: scroll;
        border: 1px solid lightgray;
    }
</style>