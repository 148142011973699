<template>
    <v-img
        :src="logoPath"
        :width="logoWidth"
        class="my-auto"
        alt="The company logo."
    ></v-img>
</template>

<script>
    export default {
        name: "TenantLogo",
        props: [
            'placement', 'scrolled'
        ],
        data () {
            return {
                logoPaths: {
                    activest: '/assets/images/logos/activest_logo.png?v=2',
                    axxets: '/assets/images/logos/axxets_logo.png?v=2'
                }
            }
        },
        computed: {
            userDomain () {
                return this.$store.getters.userDomain
            },

            logoPath () {
                return this.logoPaths[this.userDomain] ? this.logoPaths[this.userDomain] : this.logoPaths['activest']
            },

            logoWidth () {

                if (this.placement === 'login') {

                    if (this.userDomain === 'axxets') {
                        return '20rem'
                    }

                    if (this.userDomain === 'activest') {
                        return '8rem'
                    }

                    return '8rem'

                }

                if (this.placement === 'navbar') {

                    if (this.userDomain === 'axxets') {
                        if (this.scrolled) {
                            return '3rem'
                        }
                        return '11rem'
                    }

                    if (this.userDomain === 'activest') {
                        if (this.scrolled) {
                            return '3rem'
                        }
                        return '7rem'
                    }

                }

                return '7rem'

            }
        }
    }
</script>

<style scoped>
>>> .v-image, .v-responsive__content {
    transition: .4s;
}
</style>