<template>
    <v-select
        :label="label"
        v-model="selections"
        multiple
        :items="items"
        @input="emitSelections"
    >
        <template v-slot:prepend-item>
            <v-list-item
              ripple
              @click="toggle"
            >
                <v-list-item-action>
                    <v-icon :color="selections.length > 0 ? 'primary' : ''">{{ selectAllIcon }}</v-icon>
                </v-list-item-action>
                <v-list-item-content>
                    <v-list-item-title>Select All</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
        </template>
        <template v-slot:selection="{ item, index }">
            <div v-if="selections.length === items.length">
                <v-chip
                    v-if="index === 0"
                    color="primary"
                    small
                >
                    <span>All</span>
                </v-chip>
            </div>
            <div
                v-else
            >
                <span
                    v-if="index === 0"
                    class="grey--text caption"
                >
                    {{ selections.length }} selected
                </span>
            </div>

          </template>

    </v-select>
</template>

<script>
    export default {
        name: "ClearableChipsSelector",
        props: [
            'items', 'label', 'value'
        ],
        data() {
            return {
                selections: this.value
            }
        },
        watch: {
            value: function () {
                this.selections = this.value
            }
        },
        computed: {
            selectedAll () {
                return this.selections.length === this.items.length
            },

            selectedSome () {
                return this.selections.length > 0 && !this.selectedAll
            },

            selectAllIcon () {
                if (this.selectedAll) return 'mdi-checkbox-marked';
                if (this.selectedSome) return 'mdi-minus-box';
                return 'mdi-checkbox-blank-outline'
            },
        },
        methods: {
            toggle () {
                this.$nextTick(() => {
                    if (this.selectedAll) {
                        this.selections = [];
                        this.emitSelections()
                    } else {
                        this.selections = this.items.slice();
                        this.emitSelections()
                    }
                })
            },

            emitSelections(){
                this.$emit('input', this.selections)
            },

            remove (item) {
                this.selections.splice(this.selections.indexOf(item), 1);
                this.selections = [...this.selections];
                this.emitSelections()
              },

            removeAll(){
                this.selections = [];
                this.emitSelections();
            }
        }
    }
</script>

<style scoped>

</style>