import Vue from "vue";
import Vuex from "vuex";
import router from "../router";
import jwt_decode from "jwt-decode";
import {
  obtainAuthToken,
  verifyTOTP,
  refreshAuthToken,
  getUserConsPos,
  getUserDocumentList,
  getUserTransactions,
  getUserByAccountPerformance,
  getYearlyPerformance,
  getConsposData,
  getByAccountPerformance
} from "../utils/api.utils";
import { sortBy } from "../utils/data.utils";
import { getUserDomain } from "../utils/domains.utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    // Authentication
    accessToken: localStorage.getItem("avaxAccessToken"),
    refreshToken: localStorage.getItem("avaxRefreshToken"),
    isAuthenticated: localStorage.getItem("isAuthenticated") === "true",
    userName: localStorage.getItem("userName"),
    userID: localStorage.getItem("userID"),
    userRole: localStorage.getItem("avaxRole"),
    isTOTPVerified: localStorage.getItem("avaxTOTPVerified") === "true",
    needsToChangePassword:
      localStorage.getItem("needsToChangePassword") === "true",

    // Reports
    userConsPos: [],
    attemptedConspos: false,
    userTransactions: [],
    attemptedTransactions: false,
    userByAccountPerformanceRecords: [],
    attemptedByAccountPerformance: false,
    yearlyPerformance: [],
    attemptedYearlyPerformance: false,
    groupAccountMap: {},

    // Documents
    userDocuments: [],
    attemptedDocuments: false,

    // Allocation
    allocationChartFilter: null,
    allocationChartSelectedSubCategory: null,

    // Fixed Income
    fixedIncomeChartFilter: null,

    // Projected Income
    projectedIncomeChartFilter: null,
    monthNumMap: {
      1: "Jan",
      2: "Feb",
      3: "Mar",
      4: "Apr",
      5: "May",
      6: "Jun",
      7: "Jul",
      8: "Aug",
      9: "Sep",
      10: "Oct",
      11: "Nov",
      12: "Dec"
    },

    // Global Filters
    currencyFilter: [],
    groupFilter: [],
    managerFilter: [],
    accountFilter: [],
    bankFilter: [],
    transactionsStartDateFilter: "",
    transactionsEndDateFilter: "",
    transactionTypeFilter: [],
    bookingTypeFilter: [],
    transactionsAmountLowerFilter: null,
    transactionsAmountUpperFilter: null
  },

  mutations: {
    // Authentication
    updateToken(state, payload) {
      localStorage.setItem("avaxAccessToken", payload.accessToken);
      localStorage.setItem("avaxRefreshToken", payload.refreshToken);
      localStorage.setItem("isAuthenticated", "true");
      localStorage.setItem("userName", payload.userName);
      localStorage.setItem("userID", payload.userID);
      localStorage.setItem("avaxRole", payload.userRole);
      localStorage.setItem(
        "needsToChangePassword",
        payload.needsToChangePassword
      );

      state.accessToken = payload.accessToken;
      state.refreshToken = payload.refreshToken;
      state.isAuthenticated = true;
      state.userName = payload.userName;
      state.userID = payload.userID;
      state.userRole = payload.userRole;
      state.needsToChangePassword = payload.needsToChangePassword;
    },

    setChangePasswordStatus(state, payload) {
      localStorage.setItem("needsToChangePassword", payload);
      state.needsToChangePassword = payload;
    },

    removeToken(state) {
      localStorage.clear();
      state.accessToken = null;
      state.refreshToken = null;
      state.isAuthenticated = false;
      state.userName = null;
      state.userID = null;
      state.userRole = null;
      state.isTOTPVerified = false;
      state.needsToChangePassword = null;
    },

    verifyTOTP(state, payload) {
      localStorage.setItem("avaxTOTPVerified", "true");
      state.isTOTPVerified = true;
      this.commit("updateToken", payload);
    },

    // Reports
    addConsPos(state, payload) {
      state.userConsPos = payload;
    },

    addTransactions(state, payload) {
      state.userTransactions = payload;
    },

    addByAccountPerf(state, payload) {
      state.userByAccountPerformanceRecords = payload;
    },

    addYearlyPerf(state, payload) {
      state.yearlyPerformance = payload.performance;
      state.groupAccountMap = payload.accounts;
    },

    setYearlyPerfState(state, payload) {
      state.attemptedYearlyPerformance = payload;
    },

    setByAccountPerfState(state, payload) {
      state.attemptedByAccountPerformance = payload;
    },

    setConsPosState(state, payload) {
      state.attemptedConspos = payload;
    },

    setTransactionsState(state, payload) {
      state.attemptedTransactions = payload;
    },

    clearConsPos(state) {
      state.userConsPos = [];
    },

    clearTransactions(state) {
      state.userTransactions = [];
    },

    clearByAccountPerf(state) {
      state.userByAccountPerformanceRecords = [];
    },

    clearYearlyPerf(state) {
      state.yearlyPerformance = [];
    },

    // Documents
    addDocuments(state, payload) {
      state.userDocuments = payload;
    },

    setDocumentsState(state, payload) {
      state.attemptedDocuments = payload;
    },

    clearDocuments(state) {
      state.userDocuments = [];
    },

    // Allocation
    setAllocationChartFilter(state, filter) {
      state.allocationChartFilter = filter;
    },

    setAllocationSelectedSubCategory(state, filter) {
      state.allocationChartSelectedSubCategory = filter;
    },

    // Fixed Income
    setFixedIncomeChartFilter(state, filter) {
      state.fixedIncomeChartFilter = filter;
    },

    // Projected Income
    setProjectedIncomeChartFilter(state, filter) {
      state.projectedIncomeChartFilter = filter;
    },

    // Global Filters
    setGroupFilter(state, filters) {
      state.groupFilter = filters;
    },

    setManagerFilter(state, filters) {
      state.managerFilter = filters;
    },

    setAccountFilter(state, filters) {
      state.accountFilter = filters;
    },

    setBankFilter(state, filters) {
      state.bankFilter = filters;
    },

    setTransactionsStartDateFilter(state, date) {
      state.transactionsStartDateFilter = date;
    },

    setTransactionsEndDateFilter(state, date) {
      state.transactionsEndDateFilter = date;
    },

    setTransactionTypeFilter(state, filters) {
      state.transactionTypeFilter = filters;
    },

    setBookingTypeFilter(state, filters) {
      state.bookingTypeFilter = filters;
    },

    setTransactionAmountLowerFilter(state, value) {
      state.transactionsAmountLowerFilter = value;
    },

    setTransactionAmountUpperFilter(state, value) {
      state.transactionsAmountUpperFilter = value;
    },

    setCurrencyFilter(state, filters) {
      state.currencyFilter = filters;
    }
  },

  actions: {
    // Authentication
    authenticateUser({ commit }, credentials) {
      return new Promise((resolve, reject) => {
        obtainAuthToken(credentials.username, credentials.password)
          .then(response => {
            const payload = {
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
              userName: response.data.userName,
              userID: response.data.userID,
              userRole: response.data.userRole,
              needsToChangePassword: response.data.needsToChangePassword
            };

            commit("updateToken", payload);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    verifyTOTP({ commit }, totpCode) {
      return new Promise((resolve, reject) => {
        verifyTOTP(totpCode)
          .then(response => {
            const payload = {
              accessToken: response.data.access,
              refreshToken: response.data.refresh,
              userID: response.data.userID,
              userRole: response.data.userRole,
              userName: response.data.userName,
              needsToChangePassword: response.data.needsToChangePassword
            };

            commit("verifyTOTP", payload);
            resolve(response);
          })
          .catch(error => {
            reject(error);
          });
      });
    },

    logOut({ commit }) {
      commit("removeToken");
      commit("clearConsPos");
      commit("clearTransactions");
      commit("clearDocuments");
      commit("clearByAccountPerf");
      commit("clearYearlyPerf");
      router.push("/login");
    },

    inspectToken({ commit }, next) {
      const accessToken = this.state.accessToken;
      const refreshToken = this.state.refreshToken;

      if (accessToken) {
        const accessDecoded = jwt_decode(accessToken);
        const refreshDecoded = jwt_decode(refreshToken);

        const now = Date.now() / 1000;
        const accessTokenSecondsLeft = accessDecoded.exp - now;
        const refreshTokenSecondsLeft = refreshDecoded.exp - Date.now() / 1000;

        const accessTokenExpired = accessTokenSecondsLeft <= 0;
        const refreshTokenExpired = refreshTokenSecondsLeft <= 0;

        if (accessTokenExpired && !refreshTokenExpired) {
          this.dispatch("refreshToken");
        } else if (!accessTokenExpired) {
          // Do nothing, don't refresh.
        } else {
          commit("removeToken");
          router.push("/login?next=" + next);
        }
      } else {
        commit("removeToken");
        router.push("/login?next=" + next);
      }
    },

    async refreshToken({ commit }) {
      try {
        let payload1 = {
          refresh: this.state.refreshToken
        };

        let response = await refreshAuthToken(payload1);
        let payload2 = {
          accessToken: response.data.access,
          refreshToken: response.data.refresh,
          userID: this.state.userID,
          userRole: this.state.userRole,
          userName: this.state.userName,
          needsToChangePassword: this.state.needsToChangePassword
        };

        await commit("updateToken", payload2);
      } catch (e) {
        console.log(e);
      }
    },

    // Reports
    async getConsPos({ commit }) {
      try {
        await commit("setConsPosState", false);
        let response = await getUserConsPos();
        await commit("addConsPos", response.data);
        await commit("setConsPosState", true);
      } catch (e) {
        console.log(e);
        commit("setConsPosState", true);
      }
    },

    async getTransactions({ commit }) {
      try {
        await commit("setTransactionsState", false);
        let response = await getUserTransactions();
        await commit("addTransactions", response.data);
        await commit("setTransactionsState", true);
      } catch (e) {
        console.log(e);
        await commit("setTransactionsState", true);
      }
    },

    async getByAccountPerf({ commit }) {
      try {
        await commit("setByAccountPerfState", false);
        let response = await getUserByAccountPerformance();
        await commit("addByAccountPerf", response.data);
        await commit("setByAccountPerfState", true);
      } catch (e) {
        console.log(e);
        await commit("setByAccountPerfState", true);
      }
    },

    async getYearlyPerf({ commit }) {
      try {
        await commit("setYearlyPerfState", false);
        let response = await getYearlyPerformance();
        await commit("addYearlyPerf", response.data);
        await commit("setYearlyPerfState", true);
      } catch (e) {
        console.log(e);
        await commit("setYearlyPerfState", true);
      }
    },

    async getConsPosData({ commit }, postParams) {
      try {
        await commit("setYearlyPerfState", false);
        let response = await getConsposData(postParams);
        await commit("setYearlyPerfState", true);
        console.log(response);
        return response.data;
      } catch (e) {
        console.log(e);
        await commit("setYearlyPerfState", true);
      }
    },

    async getByAccountPerformanceData({ commit }, params) {
      try {
        await commit("setYearlyPerfState", false);
        let response = await getByAccountPerformance(params);
        console.log(response);
        await commit("setYearlyPerfState", true);
        return response.data;
      } catch (e) {
        console.log(e);
        await commit("setYearlyPerfState", true);
      }
    },

    // Documents
    async getUserDocuments({ commit }) {
      try {
        await commit("setDocumentsState", false);
        let response = await getUserDocumentList();
        await commit("addDocuments", response.data);
        await commit("setDocumentsState", true);
      } catch (e) {
        console.log(e);
        commit("setDocumentsState", true);
      }
    },

    // Allocation
    setAllocationChartFilter({ commit }, filter) {
      commit("setAllocationChartFilter", filter);
    },

    setAllocationSelectedSubCategory({ commit }, filter) {
      commit("setAllocationSelectedSubCategory", filter);
    },

    // Fixed Income
    setFixedIncomeChartFilter({ commit }, filter) {
      commit("setFixedIncomeChartFilter", filter);
    },

    // Projected Income
    setProjectedIncomeChartFilter({ commit }, filter) {
      commit("setProjectedIncomeChartFilter", filter);
    }
  },

  getters: {
    userDomain: () => {
      return getUserDomain();
    },
    isAdmin: state => {
      return ["Admin"].includes(state.userRole);
    },

    accountIds: state => {
      return [
        ...new Set(
          state.userConsPos
            .map(position => position.portfolio)
            .concat(
              state.userTransactions.map(transaction => transaction.portfolio)
            )
            .concat(state.userByAccountPerformanceRecords.map(x => x.portfolio))
            .concat(state.yearlyPerformance.map(x => x.portfolio))
        )
      ]
        .filter(accountId => accountId.toUpperCase() !== "ALL")
        .sort();
    },

    groups: state => {
      return [
        ...new Set(
          state.userConsPos
            .map(position => position.group)
            .concat(
              state.userTransactions.map(transaction => transaction.group)
            )
            .concat(state.userByAccountPerformanceRecords.map(x => x.group))
            .concat(state.yearlyPerformance.map(x => x.group))
        )
      ].sort();
    },

    managers: state => {
      return [
        ...new Set(
          state.userConsPos
            .map(position => position.manager)
            .concat(
              state.userTransactions.map(transaction => transaction.manager)
            )
            .concat(state.userByAccountPerformanceRecords.map(x => x.manager))
        )
      ].sort();
    },

    minTransaction: state => {
      return Math.min.apply(
        Math,
        state.userTransactions.map(tx => {
          return tx.value;
        })
      );
    },

    maxTransaction: state => {
      return Math.max.apply(
        Math,
        state.userTransactions.map(tx => {
          return tx.value;
        })
      );
    },

    minTransactionDate: state => {
      return state.userTransactions.length > 0
        ? state.userTransactions.reduce((prev, curr) => {
            return new Date(prev.date) < new Date(curr.date) ? prev : curr;
          }).date
        : "";
    },

    maxTransactionDate: state => {
      return state.userTransactions.length > 0
        ? state.userTransactions.reduce((prev, curr) => {
            return new Date(prev.date) > new Date(curr.date) ? prev : curr;
          }).date
        : "";
    },

    banks: state => {
      return [
        ...new Set(
          state.userConsPos
            .map(position => position.custodian)
            .concat(
              state.userTransactions.map(transaction => transaction.custodian)
            )
            .concat(
              state.userByAccountPerformanceRecords.map(perf => perf.custodian)
            )
        )
      ].sort();
    },

    currencies: state => {
      return [
        ...new Set(
          state.userConsPos
            .map(position => position.currency)
            .concat(
              state.userTransactions.map(transaction => transaction.currency)
            )
            .concat(
              state.userByAccountPerformanceRecords.map(perf => perf.currency)
            )
        )
      ]
        .filter(x => !!x)
        .sort();
    },

    transactionTypes: state => {
      return [
        ...new Set(
          state.userTransactions.map(
            transaction => transaction.order_type_classification
          )
        )
      ].sort();
    },

    bookingTypes: state => {
      return [
        ...new Set(
          state.userTransactions.map(transaction => transaction.booking_type)
        )
      ].sort();
    },

    consPosLoading: state => {
      return state.userConsPos.length === 0 && !state.attemptedConspos;
    },

    transactionsLoading: state => {
      return (
        state.userTransactions.length === 0 && !state.attemptedTransactions
      );
    },

    byAccountPerfLoading: state => {
      return (
        state.userByAccountPerformanceRecords.length === 0 &&
        !state.attemptedByAccountPerformance
      );
    },

    dataAsOf: state => {
      if (state.userConsPos.length === 0) {
        return "";
      }

      let dateSplits = [];
      state.userConsPos.forEach(r => {
        let dateSplit = r.date.split("-");
        let newDate = {
          year: parseInt(dateSplit[0]),
          month: parseInt(dateSplit[1]),
          day: parseInt(dateSplit[2])
        };

        dateSplits.push(newDate);
      });

      let sortFunc = sortBy(
        { name: "year", reverse: true },
        { name: "month", reverse: true },
        { name: "day", reverse: true }
      );

      let maxDateObj = dateSplits.sort(sortFunc)[0];
      let maxYear = maxDateObj.year;
      let maxMonth =
        maxDateObj.month < 10 ? `0${maxDateObj.month}` : maxDateObj.month;
      let maxDay = maxDateObj.day < 10 ? `0${maxDateObj.day}` : maxDateObj.day;

      return `${maxMonth}/${maxDay}/${maxYear}`;
    },

    nextTwelveMonths: (state, getters) => {
      // If needing to use this month as first month instead of next month as first month, remove the +1 exp.
      let currentMonthIdx = new Date(getters.dataAsOf).getUTCMonth() + 1;
      let nextTwelveMonths = [];

      for (let i = 1; i < 13; i++) {
        let newMonthIdx = currentMonthIdx + i;
        newMonthIdx = newMonthIdx > 12 ? newMonthIdx - 12 : newMonthIdx;
        let newMonthName = state.monthNumMap[newMonthIdx];
        nextTwelveMonths.push(newMonthName);
      }

      return nextTwelveMonths;
    },

    documentsLoading: state => {
      return state.userDocuments.length === 0 && !state.attemptedDocuments;
    },

    statements: state => {
      return state.userDocuments.filter(record => {
        return record.document_type === "Statement";
      });
    },

    research: state => {
      return state.userDocuments.filter(record => {
        return record.document_type === "Research";
      });
    },

    allocationChartFilter: state => {
      return state.allocationChartFilter;
    },

    allocationSelectedSubCategory: state => {
      return state.allocationChartSelectedSubCategory;
    },

    fixedIncomeChartFilter: state => {
      return state.fixedIncomeChartFilter;
    },

    projectedIncomeChartFilter: state => {
      return state.projectedIncomeChartFilter;
    },

    performanceGroups: state => {
      let groups = state.userByAccountPerformanceRecords
        .map(x => x.group)
        .concat(state.yearlyPerformance.map(x => x.group));

      return [...new Set(groups)];
    },

    performanceAccountIds: state => {
      let accounts = state.userByAccountPerformanceRecords
        .map(x => x.portfolio)
        .concat(state.yearlyPerformance.map(x => x.portfolio));

      return [...new Set(accounts)];
    },

    filteredConsPos: state => {
      return state.userConsPos
        .map(x => x)
        .filter(record => {
          return state.accountFilter.includes(record.portfolio);
        })
        .filter(record => {
          return state.groupFilter.includes(record.group);
        })
        .filter(record => {
          return state.managerFilter.includes(record.manager);
        })
        .filter(record => {
          return state.bankFilter.includes(record.custodian);
        });
    },

    statusByBank: (state, getters) => {
      let banks = [...new Set(getters.filteredConsPos.map(x => x.custodian))];
      let newRecords = [];
      banks.forEach(bank => {
        let bankRecords = getters.filteredConsPos.filter(
          x => x.custodian === bank
        );
        bankRecords.forEach(record => (record.newDate = new Date(record.date)));
        let sortFunc = sortBy({ name: "newDate", reverse: true });
        bankRecords = bankRecords.sort(sortFunc);
        newRecords.push({
          custodian: bank,
          as_of_date: bankRecords[0].date
        });
      });

      return newRecords;
    }
  }
});
