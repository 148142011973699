const activest = {
  primary: '#43B02A',
  secondary: '#253746',
  accent: '#545b62',
  background: '#f5f5f5'
};

const axxets = {
  primary: '#02CE7B',
  secondary: '#253746',
  accent: '#2b2e35',
  background: '#f5f5f5'
};

const themes = {
  activest: activest,
  axxets: axxets
};

export default themes