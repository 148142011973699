<template>
    <div>
        <v-data-table
            :items="formattedTableData"
            :headers="tableHeaders"
            dense
            hide-default-footer
            disable-sort
            disable-pagination
            :mobile-breakpoint="0"
            no-data-text="No positions found."
        >
            <template v-slot:item="{ item }">
                <tr>
                    <td :class="showTotalBar(item.aggBy)">{{ item.aggBy }}</td>
                    <td class="text-right" :class="showTotalBar(item.aggBy)">{{ item.usd_amount }}</td>
                    <td class="text-right" :class="showTotalBar(item.aggBy)">{{ item.percent }}</td>
                    <td class="text-right" :class="showTotalBar(item.aggBy)"><v-icon v-if="item.color" :color="item.color" small>mdi-circle</v-icon></td>
                </tr>
            </template>
        </v-data-table>
    </div>
</template>

<script>
    import { currencyFormatter } from "../../utils/format.utils";
    import {aggregateConsPosDataByCategory, sortBy} from "../../utils/data.utils";

    export default {
        name: "AllocationTable",
        props: ['data', 'viewBy', 'id'],
        data() {
            return {
                viewByNameMap: {
                    portfolio: 'Account',
                    category: 'Asset Class',
                    custodian: 'Custodian',
                    industry: 'Industry',
                    geolocation: 'Geolocation',
                }
            }
        },
        methods: {
            showTotalBar(aggBy){

                let totalClasses = aggBy.toLowerCase() === 'total assets' ? 'total-bar total-field' : '';
                if (aggBy.toLowerCase() === 'net assets') totalClasses += ' total-field';
                return totalClasses
            }
        },
        computed: {

            formattedViewBy() {
                return this.viewByNameMap[this.viewBy]
            },

            tableHeaders() {
                return [
                    {text: this.formattedViewBy, value: 'aggBy'},
                    {text: 'Value', value: 'usd_amount', align: 'right'},
                    {text: '%', value: 'percent', align: 'right'},
                    {text: '', value: 'color'}
                ]
            },

            aggregatedData(){
                let aggData = aggregateConsPosDataByCategory(this.data.map(x => x), this.viewBy);
                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return aggData.sort(sortFunc)
            },

            aggregatedAssets() {

                let newData = this.data.map(x => x);

                newData = newData.filter(record => {
                    return record.macro_category.toLowerCase() === 'asset'
                });

                newData = aggregateConsPosDataByCategory(newData, this.viewBy);
                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc);
            },

            formattedData(){
                let formatter = currencyFormatter;
                let formattedData = [];

                this.aggregatedData.forEach(v => formattedData.push({
                    'aggBy': v['aggBy'],
                    'usd_amount': formatter.format(v['usd_amount']),
                    'percent': (v['percent'] * 100).toFixed(2) + '%',
                    'color': v['color']
                }));

                return formattedData
            },

            formattedAssets(){
                let formatter = currencyFormatter;
                let formattedData = [];

                this.aggregatedAssets.forEach(v => formattedData.push({
                    'aggBy': v['aggBy'],
                    'usd_amount': formatter.format(v['usd_amount']),
                    'percent': (v['percent'] * 100).toFixed(2) + '%',
                    'color': v['color']
                }));

                formattedData.push({
                    'aggBy': 'Total Assets',
                    'usd_amount': formatter.format(this.totalAssets),
                    'percent': '100.00%',
                    'color': null
                });

                return formattedData
            },

            aggregatedLiab(){
                let newData = this.data.map(x => x);
                newData = newData.filter(record => {
                    return record.macro_category.toLowerCase() === 'liabilities'
                });

                newData = aggregateConsPosDataByCategory(newData, this.viewBy);
                let sortFunc = sortBy('sorting', {name: 'usd_amount', reverse: true});
                return newData.sort(sortFunc);
            },

            formattedLiab(){
                let formatter = currencyFormatter;
                let formattedData = [];

                this.aggregatedLiab.forEach(v => formattedData.push({
                    'aggBy': v['aggBy'],
                    'usd_amount': formatter.format(v['usd_amount']),
                    'percent': '100.00%',
                    'color': null
                }));

                return formattedData
            },

            totalAssets(){

                let totalAssets = 0;
                this.aggregatedAssets.forEach(record => {
                    totalAssets += record.usd_amount
                });

                return totalAssets;
            },

            totalLiab(){

                let totalAssets = 0;
                this.aggregatedLiab.forEach(record => {
                    totalAssets += record.usd_amount
                });

                return totalAssets;
            },

            formattedCategoryData(){

                let newData = [];
                this.formattedAssets.forEach(record => {
                    newData.push(record)
                });

                this.formattedLiab.forEach(record => {
                    newData.push(record)
                });

                newData.push({
                   aggBy: 'Net Assets',
                    usd_amount: currencyFormatter.format(this.totalNetValue),
                    percent: '100.00%',
                    color: null
                });

                return newData
            },

            formattedTableData() {

                if (this.viewBy === 'category') return this.formattedCategoryData;
                return this.formattedData
            },

            totalNetValue(){

                return this.totalAssets + this.totalLiab

            }
        }
    }
</script>

<style scoped>
    >>>.v-data-table-header-mobile__wrapper{
        display: none;
    }

    .legend-chip{
        height: 1rem;
    }

    .total-bar{
        border-bottom: 1px solid black !important;
    }

    .total-field{
        font-weight: bold;
    }
</style>