<template>
    <v-data-table
        hide-default-footer
        :headers="headers"
        :items="formattedTableData"
        disable-sort
        no-data-text="No positions found."
        :mobile-breakpoint="0"
    >

    </v-data-table>
</template>

<script>
    import { currencyFormatter } from "../../utils/format.utils";

    export default {
        name: "TopPositionsTable",
        props: ['tableData'],
        data() {
            return {
                headers: [
                    {text: 'Position', value: 'position'},
                    {text: 'Asset Class', value: 'category'},
                    {text: 'Value', value: 'value', align: 'end'},
                    {text: '%', value: 'percent', align: 'end'}
                ],

            }
        },

        computed: {
            aggregatedData(){

                return [...this.tableData.map(x => x).reduce((r, o) => {
                    const key = o.category + '-' + o.position_name;

                    const item = r.get(key) || Object.assign({}, o, {
                        usd_amount: 0
                    });

                    item.usd_amount += o.usd_amount;

                    return r.set(key, item);

                }, new Map).values()]

            },
            topTenPositions() {
                let unsortedData = this.aggregatedData.filter(
                    (x) => x.macro_category.toLowerCase() === 'asset'
                );

                return unsortedData.sort((a, b) => {
                    return b.usd_amount - a.usd_amount
                }).slice(0, 10)
            },

            totalValue() {
                let totalValue = 0;
                this.tableData.forEach(record => {
                    if (record.macro_category.toLowerCase() === 'asset') {
                        totalValue = totalValue + record.usd_amount
                    }
                });

                return totalValue
            },

            formattedTableData() {
                // let formatter = currencyFormatter;
                let newData = [];
                this.topTenPositions.forEach(v => newData.push(
                    {
                        position: v.position_name,
                        category: v.category,
                        value: currencyFormatter.format(v.usd_amount),
                        percent: (v.usd_amount / this.totalValue * 100).toFixed(2) + '%'
                    }
                    ));
                return newData
            }
        }
    }
</script>

<style scoped>

</style>