<template>
    <div
        @click="refreshTimer"
    >
        <v-overlay
            v-if="$store.state.needsToChangePassword"
            absolute
            :opacity=".8"
            :z-index="2001"
        >
        </v-overlay>
        <app-bar></app-bar>
            <v-main class="main-app">
                <router-view></router-view>
                <v-dialog
                    v-model="showTimeOutDialog"
                    max-width="400"
                    persistent
                >
                    <v-card>
                        <v-card-title class="headline">Your session is about to expire.</v-card-title>
                        <v-card-text>
                            <p class="text-center display-3" v-text="`${minutesLeft}:${secondsLeft}`"></p>
                            <span>Click <span class="font-weight-bold">Okay</span> to refresh your session or <span class="font-weight-bold">Log Out</span> to end your session now.</span>
                        </v-card-text>
                        <v-card-actions>
                            <v-btn
                                text
                                color="error"
                                @click="logOut"
                            >
                                Log Out
                            </v-btn>
                            <v-spacer></v-spacer>
                            <v-btn
                                color="success"
                                @click="refreshTimer"
                            >
                                Okay
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-main>
        <app-footer></app-footer>
        <mobile-bottom-nav></mobile-bottom-nav>
    </div>
</template>

<script>
    import MobileBottomNav from '../components/base/nav/MobileBottomNav'
    import AppBar from '../components/base/nav/AppBar'
    import {mapState, mapGetters, mapActions} from 'vuex'
    import AppFooter from '../components/base/nav/AppFooter'

    export default {
        name: "AppScreen",
        components: {MobileBottomNav, AppBar, AppFooter},
        data(){
            return {
                autoLogout: null,
                logoPaths: {
                    activest: '/assets/images/logos/activest_logo.png',
                    axxets: '/assets/images/logos/axxets_logo.png'
                },
                sessionTime: 60 * 30,
                showTimeOutDialog: false
            }
        },
        created(){

            // Auto Log Out
            this.autoLogout = setInterval(() => {
                    if(this.sessionTime > 0){
                        this.sessionTime--
                    }
                }, 1000
            );

        },
        mounted() {
            if (this.isDev) this.sessionTime = 60 * 1000
        },
        beforeDestroy() {
            clearInterval(this.autoLogout)
        },
        watch: {
            accountIds: function(){
                this.selectedAccountFilter = this.accountIds
            },

            groups: function(){
                this.selectedGroupFilter = this.groups
            },

            managers: function(){
                this.selectedManagerFilter = this.managers
            },

            minTransaction: function(){
                this.selectedTransactionAmountFilters = [this.minTransaction, this.maxTransaction]
            },

            maxTransaction: function(){
                this.selectedTransactionAmountFilters = [this.minTransaction, this.maxTransaction]
            },

            minTransactionDate: function(){
                this.selectedStartDateFilter = this.minTransactionDate
            },

            maxTransactionDate: function(){
                this.selectedEndDateFilter = this.maxTransactionDate
            },

            banks: function(){
                this.selectedBankFilter = this.banks
            },

            transactionTypes: function(){
                this.selectedTransactionTypeFilter = this.transactionTypes
            },

            bookingTypes: function(){
                this.selectedBookingTypeFilter = this.bookingTypes
            },

            currencies: function(){
                this.selectedCurrencyFilters = this.currencies
            },

            sessionTime: function (){
                if (this.sessionTime <= 30){
                    this.showTimeOutDialog = true;

                    if(this.sessionTime <= 0){
                        this.showTimeOutDialog = false;
                        this.logOut();
                    }

                }

                if (this.sessionTime > 30 && this.showTimeOutDialog === true){
                    this.showTimeOutDialog = false;
                }
            }
        },
        computed: {
            ...mapState([
                'groupFilter', 'managerFilter', 'accountFilter',
                'bankFilter', 'transactionsStartDateFilter',
                'transactionsEndDateFilter', 'transactionTypeFilter',
                'bookingTypeFilter', 'transactionsAmountLowerFilter',
                'transactionsAmountUpperFilter', 'currencyFilter'
            ]),
            ...mapGetters([
                'accountIds', 'groups', 'managers', 'minTransaction',
                'maxTransaction', 'banks', 'transactionTypes',
                'bookingTypes', 'minTransactionDate', 'maxTransactionDate',
                'currencies'
            ]),
            isDev() {
                return !(process.env.NODE_ENV.trim() === 'production')
            },
            selectedGroupFilter: {
                set(groups){
                    this.$store.commit('setGroupFilter', groups)
                },
                get(){
                    return this.groupFilter
                }
            },
            selectedManagerFilter: {
                set(managers){
                    this.$store.commit('setManagerFilter', managers)
                },
                get(){
                    return this.managerFilter
                }
            },
            selectedAccountFilter: {
                set(accounts){
                    this.$store.commit('setAccountFilter', accounts)
                },
                get(){
                    return this.accountFilter
                }
            },
            selectedBankFilter: {
                set(banks){
                    this.$store.commit('setBankFilter', banks)
                },
                get(){
                    return this.bankFilter
                }
            },
            selectedStartDateFilter: {
                set(date){
                    this.$store.commit('setTransactionsStartDateFilter', date)
                },
                get(){
                    return this.transactionsStartDateFilter
                }
            },
            selectedEndDateFilter: {
                set(date){
                    this.$store.commit('setTransactionsEndDateFilter', date)
                },
                get(){
                    return this.transactionsEndDateFilter
                }
            },
            selectedTransactionTypeFilter: {
                set(types){
                    this.$store.commit('setTransactionTypeFilter', types)
                },
                get(){
                    return this.transactionTypeFilter
                }
            },
            selectedCurrencyFilters: {
                set(values){
                    this.$store.commit('setCurrencyFilter', values)
                },
                get(){
                    return this.currencyFilter
                }
            },
            selectedBookingTypeFilter: {
                set(types){
                    this.$store.commit('setBookingTypeFilter', types)
                },
                get(){
                    return this.bookingTypeFilter
                }
            },
            selectedTransactionAmountFilters: {
                set(values){
                    this.$store.commit('setTransactionAmountFilters', {lower: values[0], upper: values[1]})
                },
                get(){
                    return [this.transactionsAmountLowerFilter, this.transactionsAmountUpperFilter]
                }
            },
            minutesLeft(){
                let minutes = Math.floor(this.sessionTime / 60);
                return this.zeroFill(minutes, 2)
            },
            secondsLeft(){
                let seconds = this.sessionTime - (this.minutesLeft * 60);
                return this.zeroFill(seconds, 2)
            }
        },
        methods: {
            zeroFill(number, width){
                width -= number.toString().length;
                if (width > 0){
                    return new Array( width + (/\./.test( number ) ? 2 : 1) ).join( '0' ) + number;
                }

                return number + ""; // always return a string
            },
            refreshTimer(){
                this.showTimeOutDialog = false;
                this.$nextTick(() => {
                    this.sessionTime = this.isDev ? 60 * 1000 : 60 * 30
                });
            },
            ...mapActions(['logOut'])
        }
    }
</script>

<style scoped>
    .main-app{
        margin-bottom: 5rem;
    }
</style>