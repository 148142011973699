<template>
    <v-footer
        absolute
        :class="footerClass"
        color="secondary"
    >
        <div class="text-center w100">
            <router-link to="/disclaimer">Disclaimer</router-link>
        </div>
    </v-footer>
</template>

<script>
    export default {
        name: "AppFooter",
        computed: {
            isMobile(){
                return this.$vuetify.breakpoint.smAndDown
            },
            footerClass(){
                return this.isMobile ? 'pb-14 pt-1 small-font' : 'py-4'
            }
        }
    }
</script>

<style scoped>
.w100{
    width: 100%;
}

.pb-14{
    padding-bottom: 56px !important;
}

.small-font{
    font-size: .7rem;
}
</style>