<template>
  <v-app>
    <router-view></router-view>
  </v-app>
</template>

<script>

  export default {
        name: "App",
        data(){
            return {
                logoPaths: {
                    activest: '/assets/images/logos/activest_logo.ico?v=2',
                    axxets: '/assets/images/logos/axxets_logo.ico?v=3'
                }
            }
        },
        mounted(){
            this.changeFavicon();
        },
        computed: {
            userDomain () {
                return this.$store.getters.userDomain
            },
            faviconPath(){
                return this.logoPaths[this.userDomain] ? this.logoPaths[this.userDomain] : this.logoPaths['activest']
            }
        },
        methods: {
            changeFavicon(){
                let link = document.createElement('link');
                link.type = 'image/x-icon';
                link.rel = 'shortcut icon';
                link.href = this.faviconPath;
                document.getElementsByTagName('head')[0].appendChild(link);
            }
        },
        watch: {
            userDomain: function (){
                this.changeFavicon()
            }
        }
    }
</script>

<style>
  body {
      overflow: hidden;
  }

  .primary-text{
      color: var(--v-primary-base);
  }

  .chart-box.clickable canvas{
      cursor: pointer;
    }

  @media (min-width: 1000px) {
    .a-container {
      max-width: 95% !important;
    }
  }
</style>
