<template>
        <v-card
            class="report-summary-card elevation-5"
            height="100%"
        >
            <v-card-title
                class="report-summary-card-header"
            >
                <span class="report-title" v-text="reportTitle"></span>
                <v-spacer></v-spacer>
                <v-btn
                    text
                    color="primary"
                    small
                    v-if="reportLink"
                    :to="reportLink"
                >
                    View More
                </v-btn>
                <v-text-field
                    v-if="showSearch"
                    label="Search"
                    v-model="search"
                    prepend-inner-icon="mdi-magnify"
                    filled
                    class="search-bar"
                    dense
                    clearable
                ></v-text-field>
                <download-csv v-if="showExport" :name="exportFileName" :data="exportData">
                    <v-btn
                        text
                        color="primary"
                        small
                        :disabled="exportData.length === 0"
                    >
                        Export Data
                </v-btn>
                </download-csv>
            </v-card-title>
            <v-card-text>
                <div v-show="loading" class="text-center">
                    <v-progress-circular
                        color="primary"
                        indeterminate
                        size="120"
                        class="loading-item"
                    >
                    Loading...
                    </v-progress-circular>
                </div>
                <div v-show="!loading">
                    <slot name="header"></slot>
                    <slot name="chart"></slot>
                    <slot name="table"></slot>
                </div>
            </v-card-text>
        </v-card>
</template>

<script>
    export default {
        name: "ReportSummaryCard",
        props: {
            reportTitle: {
                type: String,
                default: () => 'Report Title'
            },
            reportLink: {
                type: String,
                default: () => ''
            },
            loading: {
                type: Boolean,
                default: () => true
            },
            showSearch: {
                type: Boolean,
                default: () => false
            },
            showExport: {
                type: Boolean,
                default: () => false
            },
            exportData: {
                type: Array,
                default: () => [{col1: 'example', col2: 'example'}, {col1: 'example', col2: 'example'}]
            },
            exportFileName: {
                type: String,
                default: () => 'example.csv'
            }
        },
        data(){
            return {
                search: ''
            }
        },
        watch: {
            search: function (){
                this.$emit('search', this.search)
            }
        },
        computed: {
            consPosLoading(){
                return this.$store.getters.consPosLoading
            }
        }
    }
</script>

<style scoped>
    .report-summary-card-header{
        background-color: var(--v-secondary-base)
    }


    .chart-area{
        padding: 1rem 0;
        min-height: 13rem;
    }

    .total-text{
        color: var(--v-text-primary) !important;
    }

    .loading-item{
        margin: 13.2rem 0;
    }

    .search-bar{
        height: 3rem;
        width: .3rem;
    }


    .search-bar >>> .v-text-field__slot input {
        color: white !important;
    }

    .report-title{
        color: rgba(255, 255, 255, 0.6);
    }
</style>