import Vue from 'vue'
import App from './App.vue'
import store from './store'
import vuetify from './plugins/vuetify';
import router from './router'
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import JsonCSV from 'vue-json-csv'

// Chart.js options
Chart.plugins.unregister(ChartDataLabels);
Chart.defaults.global.animation.duration = 800;

Vue.config.productionTip = false;
Vue.config.delimiters = ['[[', ']]'];

Vue.component('downloadCsv', JsonCSV);


new Vue({
  store,
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app');
