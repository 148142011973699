<template>
    <report-summary-card
        report-title="Top 10 Positions"
        :loading="loading"
    >
        <template v-slot:table>
            <top-positions-table
                :table-data="data"
            ></top-positions-table>
        </template>
    </report-summary-card>
</template>

<script>
    import ReportSummaryCard from '../dashboard/ReportSummaryCard';
    import TopPositionsTable from './TopPositionsTable';

    export default {
        name: "TopPositionsCard",
        props: [
            'data'
        ],
        components: {
            ReportSummaryCard, TopPositionsTable
        },
        computed: {
            loading() {
                return this.$store.getters.consPosLoading
            },
        }
    }
</script>

<style scoped>

</style>